import { Notification as BaseNotification } from 'baseui/notification'
import { ToastProps, ToastOverrides } from 'baseui/toast'
import { withOverrides } from './overrides'
import React from 'react'
import constants from '../constants'

const globalOverrides: ToastOverrides = {
  Body: { style: { width: 'auto' } }
}

export const Notification = withOverrides<ToastProps>(BaseNotification, globalOverrides)

interface ErrorNotificationProps {
  hasError: boolean
  errorMessage?: React.ReactNode
}

export const ErrorNotification = ({ hasError, errorMessage }: ErrorNotificationProps) => {
  const defaultErrorMessage = (
    <>
      There was an error loading this page. <br />
      If the problem persists, please contact {constants.supportEmail}
    </>
  )

  if (hasError) {
    return (
      <Notification data-="error-message" kind="negative">
        {errorMessage || defaultErrorMessage}
      </Notification>
    )
  }
  return null
}
